<template>
  <div class="page-content">
    <img-text-section
      :bg-img-src="firstImgSrc"
      :section-title="firstTitle"
      :section-text="textList"
      :section-btn="sectionBtn"
      @Register="Register"
    >
    </img-text-section>

    <img-section
      :section-title="secondTitle"
      :img-src="secondImgSrc"
    ></img-section>

    <img-section
      :section-title="thirdTitle"
      :img-src="thirdImgSrc"
    ></img-section>
  </div>
</template>

<script>
import ImgTextSection from "@c/ImgTextSection";
import ImgSection from "@c/ImgSection";

export default {
  name: "paas-platform",
  components: {
    ImgTextSection,
    ImgSection,
  },
  data() {
    return {
      firstTitle: "开放的PaaS平台",
      textList: [
        "多种开放的数据接口，支持二次开发，可个性化定制实现多样化的数据分析需求。",
      ],
      firstImgSrc: require("../assets/images/bg_pass_1.png"),
      sectionBtn: {
        text: "体验Demo",
        url: "#",
      },
      secondTitle: "PaaS平台",
      secondImgSrc: require("../assets/images/bg_pass_2.png"),
      thirdTitle: "架构图",
      thirdImgSrc: require("../assets/images/bg_pass_3.png"),
    };
  },
  methods: {
    Register(v) {
      this.$emit("priceRegister", v);
    },
    logOut() {
      this.$parent.loginOut();
    },
  },
};
</script>

<style scoped>
</style>

<template>
  <section class="con-wrap">
    <h1 class="section-title">{{ sectionTitle }}</h1>
    <img class="section-bg" :src="imgSrc" :alt="sectionTitle" />
  </section>
</template>

<script>
export default {
  name: "img-section",
  props: {
    sectionTitle: String,
    imgSrc: String,
  },
};
</script>

<style scoped>
.con-wrap {
  padding-bottom: 2.3rem;
}

.section-title {
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  margin: 2rem 0;
  color: #212b36;
}
.section-bg {
  width: 100%;
}
</style>
